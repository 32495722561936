import logo from './img.png';
import './App.css';
import Countdown from 'react-countdown';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Countdown date={new Date("2023-06-18T00:00:00.000+03:30")} intervalDelay={0}
          precision={3}
          renderer={props => <div>
            <div style={{
              fontSize: 50,
              fontWeight: "bold"
            }}>{props.days} Days </div>
            <div >
              <span>{props.hours.toString().padStart(2, '0')}:</span>
              <span>{props.minutes.toString().padStart(2, '0')}:</span>
              <span style={{
              width: 100,
              display: "inline-block",
              textAlign: "left"
            }}>{props.seconds.toString().padStart(2, '0')}.{props.milliseconds}</span>
            </div>
          </div>}></Countdown>
          <div style={{fontSize: 15}}>till 28, Khordad, 1402</div>
      </header>
    </div>
  );
}

export default App;
